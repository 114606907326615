let state = {
    parameters: {},
    user: '',
    Expert: ''
}

let getters = {

}

let actions = {

}

let mutations = {
      upParameters(state, data) {
          state.parameters = data
      },
    upUser(state, data) {
          state.user = data
    },
    upExpert(state, data) {
         state.Expert = JSON.parse(JSON.stringify(data))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
