import Axios from 'axios'

const defaultConfig = {
    timeout: 60 * 1000,
    headers: {
        get: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
        },
        post: {
            "Content-Type": 'application/x-www-form-urlencoded;application/json'
            // "Content-Type": "application/json"
        },

    }
}

const errorHandle = (status= 0, msg='') => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            // 跳转登录页
            console.log(status)
            break;
        // 403 token过期
        case 403:
            // 如果不需要自动刷新token，可以在这里移除本地存储中的token，跳转登录页
            console.log(status)
            break;
        // 404请求不存在
        case 404:
            console.log(status)
            // 提示资源不存在
            break;
        default:
            console.log(msg);
    }
};

let _axios = Axios.create(defaultConfig)

_axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        config.data = JSON.stringify(config.data)
        return config;
    },
    function(error) {
        console.log(error)
        // Do something with request error
        error.data = {};
        error.data.msg = "服务器异常";
        return Promise.reject(error);
    }
)

_axios.interceptors.response.use(
    function (response) {
        if (response.status === 200) {
            // 处理接口中的data
            if (response.data?.data) {
                try {
                    const dataObj = JSON.parse(response.data.data);
                    if (typeof dataObj == "object" && dataObj) {
                        // 为json字符串将其转为json对象
                        response.data.data = dataObj;
                    }
                } catch (e) {
                    // 不是json字符串就不处理
                    return response.data;
                }
            }
            return response.data;
        }
        response.data.code = -1;
        response.data.msg = "服务器错误";
        return response;
    },
    function(error) {
        if (error) {
            // 请求已发出，但不在2xx范围内
            console.log(error)
            errorHandle(error.status, error);
            return Promise.reject(error);
        } else {
            // 断网
            return Promise.reject(error);
        }
    }
)

export  default _axios
