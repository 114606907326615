import Vue from 'vue'
import Vuex from 'vuex'
import Screening from "@/store/modules/screening"
import Base from "@/store/modules/base"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Screening,
    Base
  }
})
