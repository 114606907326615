<template>
  <div class="Home">

  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped lang="scss">

</style>