// import store from ''
// //url把 key转换成URL
// function getUrl(key) {
//     return store.state.base.urlMap.get(key)
// }
import axios from "axios";

function sizeof (str, charset){
    let total = 0,
        charCode,
        i,
        len;
    charset = charset ? charset.toLowerCase() : '';
    if(charset === 'utf-16' || charset === 'utf16'){
        for(i = 0, len = str.length; i < len; i++){
            charCode = str.charCodeAt(i);
            if(charCode <= 0xffff){
                total += 2;
            }else{
                total += 4;
            }
        }
    }else{
        for(i = 0, len = str.length; i < len; i++){
            charCode = str.charCodeAt(i);
            if(charCode <= 0x007f) {
                total += 1;
            }else if(charCode <= 0x07ff){
                total += 2;
            }else if(charCode <= 0xffff){
                total += 3;
            }else{
                total += 4;
            }
        }
    }
    return total;
} //计算字符串的占用字节

//把JSON字符串转换成***的字符串
function conversionJson(json) {
    if (!json) {
        return ''
    }
    let str = JSON.stringify(json);
    str = str.replace(/"/g, '***')
    return str
}

//把JSON字符串的***转换成"的字符串
function decoding(json){
    if (!json) {
        return ''
    }
    let str = json
    str = str.replace(/\*\*\*/g, '"')
    str = JSON.parse(str)
    return str
}

//简单的节流函数
function throttle(fn, delay) {
    let timer;
    return function () {
        let _this = this;
        let args = arguments;
        if (timer) {
            return;
        }
        timer = setTimeout(function () {
            fn.apply(_this, args);
            timer = null;
        }, delay)
    }
}


// 获取未来几天或者过去几天的时间
function getTime(day) {
    let today = new Date();
    let lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(lastOrNextDate);
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    month = month < 10 ? '0' + month : month;
    date = date < 10 ? '0' + date : date;
    return year + "-" + month + "-" + date;
}

// 根据生日获取年龄周岁格式YYYY/MM/DD
function getYear(str) {
    if (!str) return ''
    if (str.indexOf("-") != -1) {
        str = str.replace(/-/g, '/')
    }
    let arrs = str.split('/')
    let n = new Date().getFullYear(),y = new Date().getMonth()+1, r = new Date().getDate()
    let nian = ''
    if (arrs[1] > y) {
        nian = n -arrs[0] - 1
    }else {
        nian = n -arrs[0]
    }
    if (arrs[1] == y) {
        if (arrs[3] > r) {
            nian = n -arrs[0] - 1
        }else {
            nian = n -arrs[0]
        }
    }
    return nian
}

//获取URL参数
function getUrlVal(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    let obj = {}
    for (let i = 0; i < vars.length; i++) {
        let item = vars[i].split("=");
        obj[item[0]] = item[1]
    }
    if (variable) {
        return obj[variable]?obj[variable]:false
    }
    return obj
}

//获取随机字符串
function randomString(e) {
    e = e || 32;
    let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}


function qujing(str, nl) {
    if (!str) return -1
    str = Number(str)
    if (nl < 3) {
        if (str < 3 ) {
            return 1
        }else {
            return 0
        }
    }else if (nl >= 4 && nl <= 5) {
        if (str >= 1.5 && str <= 2) {
            return 0
        }else {
            return 1
        }
    }else if (nl >= 4 && nl <= 5) {
        if (str >= 1.5 && str <= 2) {
            return 0
        }else {
            return 1
        }
    }else if (nl >= 6 && nl <= 7) {
        if (str >= 1 && str <= 1.5) {
            return 0
        }else {
            return 1
        }
    }else if (nl == 8) {
        if (str < 1) {
            return 1
        }else {
            return 0
        }
    }else if (nl == 8) {
        if (str < 1) {
            return 1
        }else {
            return 0
        }
    }else if (nl == 9) {
        if (str < 1) {
            return 1
        }else {
            return 0
        }
    }else if (nl == 10) {
        if (str < 0.75) {
            return 1
        }else {
            return 0
        }
    }else if (nl == 11) {
        if (str < 0.25) {
            return 1
        }else {
            return 0
        }
    }else if (nl >= 12) {
        if (str < 0) {
            return 1
        }else {
            return 0
        }
    }
}
function quguangbz(nl) {
    if (!nl) return ''
    if (nl <= 3) return "+3.00"
    if (nl >= 4 && nl <=5) return '+1.50~+2.00'
    if (nl >= 6 && nl <=7) return '+1.00~+1.50'
    if (nl == 8) return "+1.00"
    if (nl == 9) return "+0.75"
    if (nl == 10) return "+0.50"
    if (nl == 11) return "+0.25"
    if (nl >= 12) return "0.00"
}


function lysl(str, nl) {
    if (!str) return -1
    str = Number(str)
    if (nl < 2) {
        if (str >= 0.01 && str <= 0.3) {
            return 0
        }else {
            return 1
        }
    }else if (nl >= 2 && nl <= 3) {
        if (str >= 0.4 && str <= 0.6) {
            return 0
        }else {
            return 1
        }
    }else if (nl >= 4 && nl <= 5) {
        if (str >= 0.6 && str <= 0.8) {
            return 0
        }else {
            return 1
        }
    }else if (nl >= 6 && nl < 8) {
        if (str >= 0.8 && str < 0.1) {
            return 0
        }else {
            return 1
        }
    }else if (nl >= 8) {
        if (str >= 1) {
            return 0
        }else {
            return 1
        }
    }
}
function lyslyybz(nl) {
    if (!nl) return ''
    if (nl < 2) return "0.01~0.3"
    if (nl >= 2 && nl <= 3) return "0.4~0.6"
    if (nl >= 4 && nl <= 5) return "0.6~0.8"
    if (nl >= 6 && nl <= 7) return "0.8~1.0"
    if (nl >= 8) return "≥1.0"
}

function yanzhou(str, nl) {
    if (!str) return -1
    str = Number(str)
    if (nl < 3) return '-1'
    if (nl == 3 || nl == 4) {
        if (str >= 18.7 && str <= 20.5) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 3 || nl == 4) {
        if (str >= 18.7 && str <= 20.5) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 5 || nl == 6) {
        if (str >= 19.6 && str <= 20.1) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 7 || nl == 8) {
        if (str >= 20.3 && str <= 21.5) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 9 || nl == 10) {
        if (str >= 21.1 && str <= 22) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 11) {
        if (str >= 20.3 && str <= 21.5) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 7 || nl == 8) {
        if (str >= 21.6 && str <= 22.4) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 12) {
        if (str >= 22.0 && str <= 22.6) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 13) {
        if (str >= 22.4 && str <= 22.9) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 14) {
        if (str >= 22.7 && str <= 23.2) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 15) {
        if (str >= 23 && str <= 23.6) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 16) {
        if (str >= 23.3 && str <= 23.6) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 17) {
        if (str >= 23.5 && str <= 24.1) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 18) {
        if (str >= 23.7 && str <= 24.3) {
            return 0
        }else {
            return 1
        }
    }
    if (nl == 19) {
        if (str >= 23.8 && str <= 24.5) {
            return 0
        }else {
            return 1
        }
    }
    if (nl >= 20) {
        if (str >= 24 && str <= 24.7) {
            return 0
        }else {
            return 1
        }
    }
}
function yanzhoubz(nl) {
    if (!nl) return ''
    if (nl < 3) return ''
    if (nl == 3 || nl == 4) {return '18.7~20.5'}
    if (nl == 5 || nl == 6) {return '19.6~21.1'}
    if (nl == 7 || nl == 8) {return '20.3~21.5'}
    if (nl == 9 || nl == 10) {return '21.1~22.0'}
    if (nl == 11) {return '21.6~22.4'}
    if (nl == 12) {return '22.0~22.6'}
    if (nl == 13) {return '22.4~22.9'}
    if (nl == 14) {return '22.7~23.2'}
    if (nl == 15) {return '23.0~23.6'}
    if (nl == 16) {return '23.3~23.9'}
    if (nl == 17) {return '23.5~24.1'}
    if (nl == 18) {return '23.7~24.1'}
    if (nl == 19) {return '23.8~24.5'}
    if (nl >= 20) {return '24.0~24.7'}
}


export default {
    decoding,
    throttle,
    getTime,
    getYear,
    getUrlVal,
    randomString,
    conversionJson,
    sizeof,
    qujing,
    quguangbz,
    lysl,
    lyslyybz,
    yanzhou,
    yanzhoubz
}
