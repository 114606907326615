<template>
  <div>
    <canvas ref="canvas" class="signature-canvas"
            :class="url?'cans':''"
            @touchstart="startDrawing"
            @touchmove="draw"
            @touchend="endDrawing"
            @touchcancel="endDrawing"></canvas>
    <img  class="imgs" v-if="url" :src="url" alt="">
    <div class="bottom">
      <button v-if="!url" @click="clearSignature">清除签名</button>
      <button v-if="!url" @click="uploadSignature">上传签名</button>

      <button style="margin: 0" v-if="url" @click="cxqm">重新签名</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "userSign",
  data() {
    return {
      canvas: null,
      ctx: null,
      drawing: false,
      url: '',
      imgdata: ''
    };
  },
  mounted() {


    this._http.post('https://interface2.visumall.com/UploadFile.ashx?cmd=Get', {
      "token": {
        "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
        "TimeSpan": "1535942069232"
      },
      "from": "AskVisumall_H5",
      "SearchCondition": {
        "UFReId": this.$store.state.Base.parameters.csid,
        "UFType": "CustomerSign",
        "UFReObject": "Customer",
      }
    })
    .then(res => {
      if (res.data.GetResult && res.data.GetResult.FullURL) {
        this.imgdata = res.data.GetResult
        this.url = res.data.GetResult.FullURL
      }else {
        this.cansInt()
      }
    })

  },
  methods: {
    cansInt() {
      this.canvas = this.$refs.canvas;
      let w = window.document.body.offsetWidth,
          h = window.document.body.offsetHeight

      this.canvas.width = w - 4
      this.canvas.height = h - 70
      this.ctx = this.canvas.getContext("2d");
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "black";
    },

    startDrawing(event) {
      this.drawing = true;
      const { clientX, clientY } = event.touches[0];
      this.ctx.beginPath();
      this.ctx.moveTo(clientX, clientY);
    },
    draw(event) {
      if (!this.drawing) return;
      const { clientX, clientY } = event.touches[0];
      this.ctx.lineTo(clientX, clientY);
      this.ctx.stroke();
    },
    endDrawing() {
      this.drawing = false;
    },
    clearSignature() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    uploadSignature() {
      // 获取Canvas中的数据URL（Base64编码）
      const signatureDataUrl = this.canvas.toDataURL("image/png");
      let bas64 = signatureDataUrl.split(',')[1]

      this._http.post('https://interface2.visumall.com/UploadFile.ashx?cmd=Upload', {
        "token": {
          "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
          "TimeSpan": "1535942069232"
        },
        "from": "AskVisumall_H5",
        "o": {
          "UFReId": this.$store.state.Base.parameters.csid,
          "UFSaveType": "OSSPrivate",
          "UFType": "CustomerSign",
          "UFReObject": "Customer",
          "FileBase64": bas64,
          "ExtensionName": ".jpg",

        }
      })
          .then(res => {
            // console.log(res.data)
            if (res.data.Id) {
              this.$dialog.alert({message: '上传成功！', theme: 'round-button'})
              .then(res => {
                wx.miniProgram.navigateBack({ delta: 1 })
              })
            }else {
              this.$dialog.alert({message: '上传失败，请稍后再试', theme: 'round-button'})
            }
          })

      // // 发送签名数据到服务器
      // axios.post('/your-upload-endpoint', { signature: signatureDataUrl })
      //     .then(response => {
      //       console.log("上传成功", response.data);
      //       // 清除Canvas或其他逻辑
      //     })
      //     .catch(error => {
      //       console.error("上传失败", error);
      //     });
    },

    cxqm() {
      this._http.post('https://interface2.visumall.com/UploadFile.ashx?cmd=DeleteById', {
        "token": {
          "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
          "TimeSpan": "1535942069232"
        },
        "from": "AskVisumall_H5",
        "Id": this.imgdata.UFId
      })
      .then(res => {
        if (res.data.DeleteByIdReturn) {
          this.url = ''
          this.cansInt()
          this.$dialog.alert({message: '重置成功，请重新上传签名', theme: 'round-button'})
        }else {
          this.$dialog.alert({message: '重置成功，请稍后再试', theme: 'round-button'})
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.signature-canvas {
  border: 1px solid #000;
  touch-action: none;
}

.bottom {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: #e5e5e5;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
  }
  button:nth-child(1) {margin-right: 30px;}
}

.imgs {
  width: 100vw;
  height: calc(100vh - 70px);
}

.cans {
  opacity: 0;
  position: absolute;
  z-index: -9;
  top: 0;
  left: 0;
}
</style>
