import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sign from "../views/sign/userSign"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/screening",
    meta: {title: ''},
    component: () => import('@/views/screening/screeningHome'),
    children: [
      {
        path: "/",
        name: "screening",
        meta: {title: '筛查报告',keepAlive: true},
        component: () => import('../views/screening/screeningJB')
      },
      {
        path: "/screeningIntroduction",
        name: "screeningIntroduction",
        meta: {title: '筛查报告'},
        component: () => import('@/views/screening/screeningIntroduction')
      }
    ]
  },
  {
    path: "/landing",
    component: () => import('@/views/landing/landingHome'),
    children: [
      {
        path: "/",
        meta: {title: '注册'},
        component: () => import('@/views/landing/wxLanding'),
      },
      {
        path: "/xy",
        name: "xy",
        meta: {title: '协议内容'},
        component: () => import('@/views/landing/xy')
      },
      {
        path: "/lszc",
        name: "lszc",
        meta: {title: '注册'},
        component: () => import('../views/provisionalRegistration/LSZC'),
      }
    ]
  },
  {
    path: "/sign",
    meta: {title: '顾客签名'},
    name: "userSign",
    component: Sign
  },
  {
    path: "/appointmentHome",
    meta: {title: '预约复查'},
    component: () => import('@/views/doctorAppointment/appointmentHome'),
    children: [
      {
        path: "/",
        meta: {title: '门店列表'},
        component: () => import('@/views/doctorAppointment/shopList')
      },
      {
        path: "/appointmentHome/reserveList",
        name: "reserveList",
        meta: {title: '门店列表'},
        component: () => import('@/views/doctorAppointment/reserveList')
      },
      {
        path: "/appointmentHome/appointmentDetails",
        name: "appointmentDetails",
        meta: {title: '预约详情'},
        component: () => import('@/views/doctorAppointment/appointmentDetails')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


export default router
