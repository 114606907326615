let state = {
    isYzTel: false,
    cheid: '', //筛查ID
}

let getters = {

}

let actions = {

}

let mutations = {
    upIsYzTel(state, data) {
        state.isYzTel = data
    },
    upCheid(state, data) {
        state.cheid = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
