"use strict"

import _axios from 'axios'

//axios的基础配置
const serve = _axios.create({
    timeout: 20 * 1000
})

//请求拦截器
serve.interceptors.request.use(config => {
    //设置header
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded"
    // config.headers["Content-Type"] = "application/json;  charset=UTF-8"
    config.data = JSON.stringify(config.data)
    return config
}, error => {
    return Promise.reject(error)
})

//相应拦截器
serve.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // 错误信息提示，具体配置自己修改
        if (error.response.status) {
            if (error.response.status == 400) {
                return 400;
            } else if (error.response.status == 401) {
                return 400;
            } else if (error.response.status == 404) {
                return 404;
            } else if (error.response.status == 500) {
                return 500;
            } else if (error.response.status == 560) {
                return 560;
            }else {
                return '请求错误，稍后再试';
            }
        }else {
            return error.response
        }
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);



export default serve;
