<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
Date.prototype.pattern=function(fmt) {
  let p = {
    "M+" : this.getMonth()+1, //月份
    "d+" : this.getDate(), //日
    "h+" : this.getHours()%12 == 0 ? 12 : this.getHours()%12, //小时
    "H+" : this.getHours(), //小时
    "m+" : this.getMinutes(), //分
    "s+" : this.getSeconds(), //秒
    "q+" : Math.floor((this.getMonth()+3)/3), //季度
    "S" : this.getMilliseconds() //毫秒
  };
  let week = {
    "0" : "/u65e5",
    "1" : "/u4e00",
    "2" : "/u4e8c",
    "3" : "/u4e09",
    "4" : "/u56db",
    "5" : "/u4e94",
    "6" : "/u516d"
  };
  if(/(y+)/.test(fmt)){
    fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
  }
  if(/(E+)/.test(fmt)){
    fmt=fmt.replace(RegExp.$1, ((RegExp.$1.length>1) ? (RegExp.$1.length>2 ? "/u661f/u671f" : "/u5468") : "")+week[this.getDay()+""]);
  }
  for(var k in p){
    if(new RegExp("("+ k +")").test(fmt)){
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (p[k]) : (("00"+ p[k]).substr((""+ p[k]).length)));
    }
  }
  return fmt;
}
export default {
  name: 'app',
  created() {
    let obj = this._pf.getUrlVal()
    this.$store.commit('Base/upParameters', JSON.parse(JSON.stringify(obj)))
    if (obj.type) {
      this.$router.push({
        path: '/' + obj.type
      })
    }
  }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: .14rem;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}
* {
  margin: 0;
  padding: 0;
}

</style>
