import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import pf from './publicFn/pf'
import http from '@/config/http'
import http2 from '@/config/http2'
Vue.prototype._http = http
Vue.prototype._http2 = http2
Vue.prototype._pf = pf

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
